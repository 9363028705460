<template>
    <div>
        <div class="container-with-sliding-text">
            <b-button type="is-dark" icon-left="plus" class="circular-button" v-on:click="$emit('click')"
                @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" outlined />
            <span v-if="showTextOnMobile || !isMobile" class="sliding-text subtitle is-5">{{ displayedText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        showTextOnMobile: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            buttonHovered: false
        }
    },
    computed: {
        displayedText() {
            if (this.buttonHovered)
                return this.text;
            return "";
        },
        isMobile() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.tablet;
        },
    },
    methods: {
        onMouseEnter() {
            this.buttonHovered = true;
        },
        onMouseLeave() {
            this.buttonHovered = false;
        }
    }
}
</script>

<style>
.circular-button {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 0.75rem;
}

.sliding-text {
    margin-left: -5px;
    opacity: 0;
    transition: opacity 0.3s ease, margin-left 0.3s ease;
}

.container-with-sliding-text:hover .sliding-text {
    opacity: 1;
    margin-left: 5px;
}
</style>