import axios, { AxiosResponse } from 'axios';
import { constructListOfficeSpacesUrl, createGetDeleteOfficeSpaceUrl, createGetOfficeSpaceNameUrl, getModifyOfficeSpacesUrl, constructRoomsApiUrl, constructAnalyticsUrl, constructGetProfilePictureUrl, constructGetUserPrincipalNameUrl, IRoom } from '../../endpoints';
import { setSessionCookie, getCookie } from './cookieHandler';

interface IOfficeSpace {
  tid: string;
  id: string;
  client: string;
  name: string;
  isPrivate: boolean;
}

class HybRoomsConnector {
  private defaultAxiosCfg: object;

  constructor() {
    this.defaultAxiosCfg = { headers: { 'content-type': 'application/json' }, withCredentials: true };
  }

  private setHybRoomsIdentifier(response: AxiosResponse) {
    setSessionCookie("hybRoomsIdentifier", response.headers["x-hybrooms-identifier"]!);
  }

  private deleteHybRoomsIdentifier() {
    setSessionCookie("hybRoomsIdentifier", "logged_out");
  }

  private getHybRoomsIdentifier() {
    return getCookie("hybRoomsIdentifier");
  }

  private getAuthorizedCfg() {
    return { headers: { "content-type": "application/json", "Authorization": this.getHybRoomsIdentifier() }, withCredentials: true };
  }

  async loginMSTeams(authToken: string, clientType: string) {
    const data = await axios.get("https://userapi.hybrooms.com/auth/login/msteams", {
      headers: {
        "Authorization": authToken,
        "TimeZoneName": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "DayOffset": 0,
        "ClientType": clientType,
        "HybRoomsClient": "msteams",
        "HybRoomsIdentifier": this.getHybRoomsIdentifier()
      },
      withCredentials: true
    }).then(response => {
      this.setHybRoomsIdentifier(response);
      return response.data;
    }).catch(error => {
      if (error.response.data == "AdminConsentRequired")
        this.setHybRoomsIdentifier(error.response);
      throw "AdminConsentRequired";
    })
    return data;
  }

  async loginZoom(authHeader: string, clientType: string) {
    const data = await axios.get("https://userapi.hybrooms.com/auth/login/zoom", {
      headers: {
        "Authorization": authHeader,
        "TimeZoneName": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "DayOffset": 0,
        "ClientType": clientType,
        "HybRoomsClient": "zoom"
      },
      withCredentials: true
    }).then(response => {
      this.setHybRoomsIdentifier(response);
      return response.data;
    })
    return data;
  }

  async loginPublic() {
    const data = await axios.get("https://userapi.hybrooms.com/public/generateuser", this.defaultAxiosCfg)
      .then(response => {
        this.setHybRoomsIdentifier(response);
        return response.data;
      })
    return data;
  }

  async checkout(clientType: string, plan: string, period: string, authToken: string, locale: string, oldSession: string) {
    const data = await axios.get("https://userapi.hybrooms.com/public/checkout", {
      headers: {
        "Authorization": authToken,
        "Clienttype": clientType,
        "Plan": plan,
        "Period": period,
        "Locale": locale,
        "Oldsession": oldSession
      },
    }).then(response => {
      return response.data;
    }).catch(() => {
      throw "YouHaveToBeAGlobalAdmin";
    })
    return data;
  }

  listOfficeSpaces(tid: string): Promise<AxiosResponse> {
    const url = constructListOfficeSpacesUrl(tid);
    return axios.get(url, this.getAuthorizedCfg())
  }

  getOfficeSpace(tid: string, officeSpaceId: string): Promise<AxiosResponse> {
    const url = createGetDeleteOfficeSpaceUrl(tid, officeSpaceId);
    return axios.get(url, this.getAuthorizedCfg());
  }

  getAnalytics(tid: string, officeSpaceId: string): Promise<AxiosResponse> {
    const url = constructAnalyticsUrl(tid, officeSpaceId);
    return axios.get(url, this.getAuthorizedCfg());
  }

  getOfficeSpaceName(tid: string, officeSpaceId: string): Promise<AxiosResponse> {
    const url = createGetOfficeSpaceNameUrl(tid, officeSpaceId);
    return axios.get(url, this.getAuthorizedCfg());
  }

  createOfficeSpace(officeSpace: IOfficeSpace): Promise<AxiosResponse> {
    const url = getModifyOfficeSpacesUrl(officeSpace.client, officeSpace.tid, officeSpace.id, officeSpace.name, officeSpace.isPrivate);
    return axios.put(url, {}, this.getAuthorizedCfg());
  }

  deleteOfficeSpace(officeSpace: IOfficeSpace): Promise<AxiosResponse> {
    const url = createGetDeleteOfficeSpaceUrl(officeSpace.tid, officeSpace.id);
    return axios.delete(url, { ...{ data: officeSpace }, ...this.getAuthorizedCfg() });
  }

  createRoom(room: IRoom): Promise<AxiosResponse> {
    return axios.put(constructRoomsApiUrl(room), room, this.getAuthorizedCfg());
  }

  updateRoom(room: IRoom): Promise<AxiosResponse> {
    return axios.post(constructRoomsApiUrl(room), room, this.getAuthorizedCfg());
  }

  deleteRoom(room: IRoom): Promise<AxiosResponse> {
    return axios.delete(constructRoomsApiUrl(room), this.getAuthorizedCfg());
  }

  getProfilePicture(tid: string, id: string) {
    return axios.get(constructGetProfilePictureUrl(tid, id), this.getAuthorizedCfg());
  }

  getUserPrincipalName(tid: string, id: string): Promise<AxiosResponse> {
    const url = constructGetUserPrincipalNameUrl(tid, id);
    return axios.get(url, this.getAuthorizedCfg());
  }

  logout() {
    // Delete the identifier to enable offline users to log out
    axios.post('https://userapi.hybrooms.com/logout', {}, this.getAuthorizedCfg()).finally(() => this.deleteHybRoomsIdentifier());
  }

  getAIContent(tid: string, transcript: string, type_: string) {
    return axios.post(`https://userapi.hybrooms.com/analytics/aicontent?type=${type_}&tid=${tid}`, transcript, this.getAuthorizedCfg());
  }

  submitForm(formContent: object, contactFormType: string) {
    return axios.post(`https://userapi.hybrooms.com/public/submitform?type=${contactFormType}`, formContent);
  }
}

export const hybRoomsConnector = new HybRoomsConnector();