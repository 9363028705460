export const ENDPOINTS = {
    listofficeSpaces: 'https://userapi.hybrooms.com/listofficespaces',
    officeSpaces: 'https://userapi.hybrooms.com/officespaces',
    analytics: 'https://userapi.hybrooms.com/analytics',
    rooms: 'https://userapi.hybrooms.com/rooms',
    profilePicture: 'https://userapi.hybrooms.com/profilepicture',
    generateUser: 'https://userapi.hybrooms.com/public/generateuser',
    users: 'https://userapi.hybrooms.com/users',
};

export const webSocketEndpoint = "wss://ws.hybrooms.com";

export interface IRoom {
    tid: string;
    officeSpaceId: string;
    id: string;
}

function addQueryStringParams(endpoint: string, params: Record<string, string | number | boolean>): string {
    const queryStringParams = Object.keys(params)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join("&");

    return endpoint + "?" + queryStringParams;
}

export function getModifyOfficeSpacesUrl(client: string, tid: string, id: string, name: string, isPrivate: boolean) {
    return addQueryStringParams(ENDPOINTS.officeSpaces, { client: client, tid: tid, id: id, name: name, private: isPrivate });
}

export function constructListOfficeSpacesUrl(tid: string) {
    return addQueryStringParams(ENDPOINTS.listofficeSpaces, { tid: tid });
}

export function createGetDeleteOfficeSpaceUrl(tid: string, officeSpaceId: string) {
    return addQueryStringParams(ENDPOINTS.officeSpaces, { tid: tid, officeSpaceId: officeSpaceId });
}

export function createGetOfficeSpaceNameUrl(tid: string, officeSpaceId: string) {
    return addQueryStringParams(ENDPOINTS.officeSpaces, { tid: tid, officeSpaceId: officeSpaceId, nameOnly: true });
}

export function constructRoomsApiUrl(room: IRoom) {
    return addQueryStringParams(ENDPOINTS.rooms, { tid: room.tid, officeSpaceId: room.officeSpaceId, id: room.id });
}

export function constructGetProfilePictureUrl(tid: string, id: string) {
    return addQueryStringParams(ENDPOINTS.profilePicture, { tid: tid, id: id });
}

export function constructGetUserPrincipalNameUrl(tid: string, id: string) {
    return addQueryStringParams(ENDPOINTS.users, { tid: tid, id: id });
}

export function constructAnalyticsUrl(tid: string, officeSpaceId: string) {
    return addQueryStringParams(ENDPOINTS.analytics, { tid: tid, officeSpaceId: officeSpaceId });
}