import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from './store/index';
import './lib/cookieHandler';

import * as messages from './languages/languages.json';

function getDefaultLocale() {
  const selectedLocale = Vue.prototype.$getCookie("selectedLocale");
  if (selectedLocale)
    return selectedLocale;
  let locales = Object.keys(store.state.LOCALES).filter(k => k.toLowerCase() == navigator.language.toLowerCase());
  if (locales.length == 0)
    locales = Object.keys(store.state.LOCALES).filter(k => k.toLowerCase().startsWith(navigator.language.slice(0, 2)));
  if (locales.length == 0)
    return "en-US";
  return locales[0];
}

Vue.use(VueI18n);

export default new VueI18n({
  locale: getDefaultLocale(),
  messages,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'tr-TR': function (choice, _choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      const isnum = /^\d+$/.test(choice.toString());

      if (choice === 0 || isnum) {
        return 0;
      }

      return 1;
    }
  }
})