<template>
    <div :class="isModal ? 'modal-card' : ''">
        <section :class="isModal ? 'modal-card-body' : ''">
            <b-field v-if="showTitle">
                <h1 class="title mb-5">{{ title ? title : $t('officeSpaceSettings') }}</h1>
            </b-field>

            <form v-on:submit.prevent>
                <b-field :label="$t('officeSpaceName')" label-position="on-border">
                    <b-input ref="nameInput" v-model="officeSpace.name"
                        :minlength="$CONSTANTS.MIN_OFFICE_SPACE_NAME_LENGTH"
                        :maxlength="$CONSTANTS.MAX_OFFICE_SPACE_NAME_LENGTH"
                        :has-counter="officeSpace.name.length >= $CONSTANTS.MAX_OFFICE_SPACE_NAME_LENGTH * 0.85"
                        @input="onInput"></b-input>
                </b-field>
                <!--b-field>
                    <div class="is-flex is-justify-content-space-between" expanded>
                        <b-field grouped expanded :style="isEnterprisePlan ? '' : 'opacity: 0.5;'">
                            <b-field>
                                <b-icon icon="lock" size="is-small"></b-icon>
                            </b-field>
                            <b-field>
                                <b>{{ $t("private") }}</b>
                            </b-field>
                            <b-field v-if="!isEnterprisePlan">
                                <b-tag type="is-info">ENTERPRISE</b-tag>
                            </b-field>
                        </b-field>
                        <b-field>
                            <b-switch v-model="officeSpace.isPrivate" :disabled="!isEnterprisePlan"
                                @input="onInput"></b-switch>
                        </b-field>
                    </div>
                </b-field-->

                <div v-if="showButtons" class="is-flex is-justify-content-space-between mt-4">
                    <b-button :label="$t('interaction.cancel')" @click="cancel" :disabled="!canCancel" />
                    <b-button :label="$t('interaction.OK')" native-type="submit" type="is-primary" v-on:click="submit"
                        :disabled="officeSpace.name.length < $CONSTANTS.MIN_OFFICE_SPACE_NAME_LENGTH || officeSpace.name.length > $CONSTANTS.MAX_OFFICE_SPACE_NAME_LENGTH" />
                </div>
            </form>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        officeSpaceData: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        isModal: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showButtons: {
            type: Boolean,
            default: true
        },
        canCancel: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            officeSpace: this.officeSpaceData
        }
    },
    mounted() {
        this.$refs.nameInput.focus();
    },
    computed: {
        isEnterprisePlan() {
            return this.$store.state.subscriptionPlan == "enterprise";
        }
    },
    methods: {
        submit() {
            if (this.officeSpace.name) {
                this.$emit('submitNewOfficeSpace', this.officeSpace);
                this.$emit('close');
            }
        },
        onInput() {
            this.$emit("input", this.officeSpace);
        },
        cancel() {
            this.$emit('cancel');
            this.$emit('close');
        }
    }
}
</script>