<template>
    <div class="modal-card">
        <section class="modal-card-body">
            <b-field>
                <h1 class="title mb-5">{{ title ? title : $t('roomSettings') }}</h1>
            </b-field>

            <form v-on:submit.prevent>
                <b-field :label="room.adHoc ? $t('subjectOfCall') : $t('roomName')" label-position="on-border">
                    <b-input ref="nameInput" v-model="room.name" :minLength="$CONSTANTS.MIN_ROOM_NAME_LENGTH"
                        :maxlength="$CONSTANTS.MAX_ROOM_NAME_LENGTH"
                        :has-counter="room.name.length >= $CONSTANTS.MAX_ROOM_NAME_LENGTH * 0.85"></b-input>
                </b-field>
                <!--b-collapse v-if="room.adHoc" :open="!room.adHoc" position="is-top" aria-id="contentIdForA11y4">
                    <template #trigger="props">
                        <a aria-controls="contentIdForA11y4" :aria-expanded="props.open">
                            {{ $t("advancedSettings") }}
                            <span class="icon-text">
                                <span class="icon">
                                    <i>
                                        <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
                                    </i>
                                </span>
                            </span>
                        </a>
                    </template>
<room-settings class="mt-4" :roomData="roomData" />
</b-collapse-->
                <!--room-settings v-else :roomData="roomData" /-->

                <div class="is-flex is-justify-content-space-between mt-4">
                    <b-button :label="$t('interaction.cancel')" @click="cancel" />
                    <b-button :label="$t('interaction.OK')" native-type="submit" type="is-primary" v-on:click="submit"
                        :disabled="!roomNameOk || !roomNameChanged" />
                </div>
            </form>
        </section>
    </div>
</template>

<script>
import Vue from "vue";
import { hybRoomsConnector } from '@/lib/connector';
//import RoomSettings from '@/components/RoomSettings.vue';

export default {
    /*components: {
        "room-settings": RoomSettings
    },*/
    props: {
        roomData: {
            type: Object,
            required: true
        },
        roomIndex: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            room: this.roomData,
            initialRoomName: this.roomData.name.substring(0)
        }
    },
    computed: {
        roomNameOk() {
            return this.trimmedName.length >= this.$CONSTANTS.MIN_ROOM_NAME_LENGTH && this.trimmedName.length <= this.$CONSTANTS.MAX_ROOM_NAME_LENGTH;
        },
        roomNameChanged() {
            return this.trimmedName != this.initialRoomName;
        },
        isEnterprisePlan() {
            return this.$store.state.subscriptionPlan == "enterprise";
        },
        trimmedName() {
            return this.room.name.trim();
        }
    },
    mounted() {
        this.$refs.nameInput.focus();
    },
    methods: {
        submit() {
            if (this.trimmedName.length == 0)
                return;
            if (this.initialRoomName.length == 0) {
                this.$emit("submitNewRoom", this.room);
                this.$emit("close");
                return;
            }
            const loadingComponentId = this.$showLoading(this.$t("updatingRoom"), this.$store.state.demoMode.active ? "" : this.$t("waitingForMicrosoftServers"), 0.15);
            setTimeout(() => this.$closeLoading(loadingComponentId), 10 * 1000);
            hybRoomsConnector.updateRoom(this.room)
                .then(response => {
                    const room = response.data.room;
                    const idx = this.$store.state.rooms.findIndex((r) => r.id == room.id && r.officeSpaceId == room.officeSpaceId && r.tid == room.tid);
                    Vue.set(this.$store.state.rooms, idx, room);
                    this.$buefy.toast.open({
                        duration: 3500,
                        message: this.$t("sentences.successfullyUpdatedRoom"),
                        type: "is-success",
                        pauseOnHover: true
                    })
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        duration: 3500,
                        message: this.$t("sentences.failedToUpdateRoom"),
                        type: "is-danger",
                        pauseOnHover: true
                    })
                })
                .finally(() => {
                    this.$closeLoading(loadingComponentId);
                    this.$emit("close");
                });
        },
        cancel() {
            this.$emit('cancel');
            this.$emit('close');
        }
    }
}
</script>