<template>
    <div v-if="clientInitialized" ref="officeSpaceView">
        <div class="overlay" v-if="gettingOfficeSpace || gettingParticipants">
            <loading-dots />
        </div>
        <div class="mx-3">
            <div class="columns">
                <div v-if="showCalendar" class="column box is-2 is-hidden-mobile mt-3">
                    <calendar class="sticky" v-on:joinMeetingFromCalendar="joinMeeting"
                        v-on:fetchEventsWithDayOffset="fetchEventsWithDayOffset" :isLoading="isLoadingCalendar" />
                </div>
                <div class="column mt-3">
                    <div v-if="viewedOfficeSpaceId" class="is-flex is-align-items-center mb-3">
                        <span>
                            <h5 class="title is-5">{{ $t("rooms") }}</h5>
                        </span>
                        <circular-button-with-sliding-text class="ml-2" :text="$t('createRoom')"
                            v-on:click="$emit('createRoom')"></circular-button-with-sliding-text>
                    </div>
                    <div v-if="viewedOfficeSpaceId && allRoomsOfTheOfficeSpace.length > 0" class="columns is-multiline">
                        <div class="column is-full-mobile is-full-tablet is-half-desktop is-one-third-widescreen"
                            v-for="[idx, room] in specialRooms.entries()" :key="room.id">
                            <room :roomData="room" :roomIndex="idx"
                                :assertAttributeExists="room.id == '0000' ? 'doNotDisturb' : null"
                                v-on:joinRoom="joinRoom" v-on:unsetDoNotDisturb="unsetDoNotDisturb"
                                style="height: 100%;" />
                        </div>
                        <div class="column is-full-mobile is-full-tablet is-half-desktop is-one-third-widescreen"
                            v-for="[idx, room] in fixedRooms.entries()" :key="room.id">
                            <room :roomData="room" :roomIndex="idx" v-on:joinRoom="joinRoom" style="height: 100%;" />
                        </div>
                    </div>
                    <div v-if="Object.keys(adHocRoomsWithOngoingMeeting).length > 0">
                        <hr>
                        <h5 class="title is-5">{{ $t("ongoingCalls") }}</h5>
                        <div class="columns is-multiline">
                            <div class="column is-full-mobile is-full-tablet is-half-desktop is-one-third-widescreen"
                                v-for="[idx, room] in adHocRoomsWithOngoingMeeting.entries()" :key="room.id">
                                <room :roomData="room" :roomIndex="idx" v-on:joinRoom="joinRoom"
                                    style="height: 100%;" />
                            </div>
                        </div>
                    </div>
                    <div v-if="!viewedOfficeSpaceId" class="is-flex is-align-items-center mb-3">
                        <span>
                            <h5 class="title is-5">{{ $t("officeSpaces") }}</h5>
                        </span>
                        <circular-button-with-sliding-text class="ml-2" :text="$t('createOfficeSpace')"
                            v-on:click="$emit('createOfficeSpace')"></circular-button-with-sliding-text>
                    </div>
                    <div v-if="!viewedOfficeSpaceId" class="columns is-multiline">
                        <!--h5 class="title is-5">{{ $t("selectOfficeSpaceToStart") }}</h5-->
                        <div class="column is-full-mobile is-full-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
                            v-for="o in officeSpaces" :key="o.id">
                            <office-space :officeSpaceData="o" v-on:goToOfficeSpace="goToOfficeSpace" />
                        </div>
                    </div>
                </div>
                <div v-if="viewedOfficeSpaceId" class="column box is-2 is-hidden-mobile mt-3">
                    <h5 class="title is-6">{{ $t("usersCurrentlyInTheOffice") }}</h5>
                    <b-field>
                        <b-input v-model="searchInput" icon="search" :placeholder="$t('search')"></b-input>
                    </b-field>
                    <b-field>
                        <person v-if="showMe" :user="$store.state.me" :withName="true" :size="32" />
                    </b-field>
                    <b-field v-for="p in otherParticipantsOfTheOfficeSpace" :key="p.tid + p.id">
                        <person :user="p" :withName="true" :size="32" />
                    </b-field>
                </div>
            </div>
        </div>
        <div v-if="isMobile" style="height: 4rem;"></div>
        <quick-call-button v-if="viewedOfficeSpaceId" v-on:createAdHocMeeting="$emit('createAdHocMeeting')" />
        <mobile-bottom-bar style="margin-top: 4rem;"></mobile-bottom-bar>
    </div>
</template>

<script>
import Vue from "vue";
import {
    authentication, chat, call
} from "@microsoft/teams-js";
// import {
//     PublicClientApplication
// } from "@azure/msal-browser";
import axios from "axios";

import {
    webSocketEndpoint
} from "../../endpoints";

import {
    hybRoomsConnector
} from "@/lib/connector";

import {
    createDummyPeople
} from "@/lib/dummy";

import Calendar from "@/components/Calendar.vue";
import OfficeSpace from "@/components/OfficeSpace.vue";
import Room from "@/components/Room.vue";
import Person from "@/components/Person.vue";
import QuickCallButton from "@/components/QuickCallButton.vue";
import MobileBottomBar from "@/components/MobileBottomBar.vue";
import Confirm from "@/components/Confirm.vue";
import LoadingDots from "@/components/LoadingDots.vue";

import OnlineMeetingMockup from '@/components/OnlineMeetingMockup.vue';
import CircularButtonWithSlidingText from "@/components/CircularButtonWithSlidingText.vue";

// const CLIENT_ID = "f700bda7-fa03-4ef7-a72a-0f28bb495de5";
// const TENANT_ID = "ef7a6fdf-4bb5-414a-804c-101441bde29e";

export default {
    async beforeRouteUpdate(to, _from, next) {
        if (this.$store.state.history.length == 0) {
            this.$store.state.history.push(_from.path)
        }
        if (new Date().getTime() - this.$store.state.lastBack.timestamp < 20 && this.$store.state.lastBack.from == _from.path) {
            this.$store.state.historyIndex--;
        }
        else if (new Date().getTime() - this.$store.state.lastForward.timestamp < 20 && this.$store.state.lastForward.from == _from.path) {
            this.$store.state.historyIndex++;
        }
        else {
            this.$store.state.history.splice(this.$store.state.historyIndex, this.$store.state.history.length);
            this.$store.state.history.push(to.path);
            this.$store.state.historyIndex++;
        }
        this.$store.state.viewTenantId = to.params.tid;
        this.$store.state.viewedOfficeSpaceId = to.params.officeSpaceId;
        this.fetchOfficeSpace();
        next();
    },
    async beforeRouteLeave(_to, _from, next) {
        this.$store.state.viewTenantId = "";
        this.$store.state.viewedOfficeSpaceId = "";
        this.closeWebSocket();
        next();
    },
    components: {
        "room": Room,
        "office-space": OfficeSpace,
        "calendar": Calendar,
        "person": Person,
        "mobile-bottom-bar": MobileBottomBar,
        "quick-call-button": QuickCallButton,
        "loading-dots": LoadingDots,
        "circular-button-with-sliding-text": CircularButtonWithSlidingText
    },
    data() {
        return {
            searchInput: "",
            roomIdToJoin: null,
            retryWebsocketCount: 0,
            webSocketConnection: null,
            webSocketTimeOut: null,
            subscribedOfficeSpace: {
                "tid": "",
                "officeSpaceId": ""
            },
            showCalendar: true,
            loadingComponentId: null,
            isLoadingCalendar: false,
            gettingOfficeSpace: false,
            gettingParticipants: false,
            randomTenantId: crypto.randomUUID(),
            randomUserId: crypto.randomUUID(),
            unknownUserName: "Unknown User"
        };
    },
    computed: {
        clientInitialized() {
            if (this.$store.state.hybRoomsClient)
                return true;
            return false;
        },

        showMe() {
            if (this.$store.state.me && this.searchInput.trim() == "")
                return true;
            return false;
        },

        isMobile() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.tablet;
        },

        viewTenantId() {
            return this.$store.state.viewTenantId;
        },

        viewedOfficeSpaceId() {
            return this.$store.state.viewedOfficeSpaceId;
        },

        globalOfficeSpaceId() {
            return this.viewTenantId + '.' + this.viewedOfficeSpaceId;
        },

        defaultOfficeSpace() {
            return this.$store.state.defaultOfficeSpace;
        },

        isEnterprisePlan() {
            return this.$store.state.subscriptionPlan == "enterprise";
        },

        officeSpaces() {
            return this.$store.state.officeSpaces;
        },

        allRoomsOfTheOfficeSpace: {
            get() {
                return this.$store.state.rooms.filter(r => r.tid == this.viewTenantId && r.officeSpaceId == this.viewedOfficeSpaceId);
            },

            set(rooms) {
                this.$store.state.rooms = rooms;
            }
        },

        specialRooms() {
            const nameMap = { "0000": this.$t("doNotDisturb"), "0001": this.$t("coffeeLounge"), "0002": this.$t("coffeeLounge") }
            const specialRooms = this.allRoomsOfTheOfficeSpace.filter(r => r.id == "0000" || r.id == "0001" || r.id == "0002").sort((a, b) => a.id.localeCompare(b.id));
            for (const r of specialRooms) {
                r.name = nameMap[r.id];
            }
            return specialRooms;
        },

        rooms() {
            return this.allRoomsOfTheOfficeSpace.filter(r => r.id != "0000" && r.id != "0001" && r.id != "0002");
        },

        fixedRooms() {
            return this.rooms.filter(r => !r.adHoc);
        },

        adHocRooms() {
            return this.rooms.filter(r => r.adHoc);
        },

        adHocRoomsWithOngoingMeeting() {
            return this.adHocRooms.filter(r => this.isRoomEmpty(r.id));
        },

        flattenedAllParticipants() {
            const allParticipants = this.$store.state.participants?.[this.viewTenantId]?.[this.viewedOfficeSpaceId] || {};
            if (Object.keys(allParticipants).length === 0)
                return [];

            const flatList = [];
            for (const roomId in allParticipants) {
                flatList.push(...allParticipants[roomId]);
            }
            return flatList;
        },

        otherParticipantsOfTheOfficeSpace() {
            let uniqueParticipants = {}
            for (const p of this.flattenedAllParticipants) {
                if (p.tid == this.$store.state.me.tid && p.id == this.$store.state.me.id)
                    continue
                uniqueParticipants[p.tid + p.id] = p;
            }
            if (this.searchInput.trim() == "")
                return Object.values(uniqueParticipants);
            return this.filterPeople([this.$store.state.me].concat(Object.values(uniqueParticipants)));
        },

        globalUserId() {
            return [this.$store.state.me.tid, this.$store.state.me.id].join(".");
        },

        appIdURI() {
            return this.$CONSTANTS.APP_ID_URIS[process.env.NODE_ENV]
        },

        appURI() {
            return this.$CONSTANTS.APP_URIS[process.env.NODE_ENV]
        }
    },

    mounted() {
        if (!this.clientInitialized) {
            if (this.$store.state.viewTenantId) {
                let redirectPath = `/init?redirectTenant=${this.$store.state.viewTenantId}`;
                if (this.$store.state.viewedOfficeSpaceId)
                    redirectPath = redirectPath.concat(`&redirectOffice=${this.$store.state.viewedOfficeSpaceId}`);
                this.$router.push(redirectPath);
            }
            else
                this.$router.push("/init");
            return;
        }
        this.roomIdToJoin = this.$router.currentRoute.query.joinRoom;
        this.fetchOfficeSpace();
        /*const pca = new PublicClientApplication({
            auth: {
                clientId: "f700bda7-fa03-4ef7-a72a-0f28bb495de5"
            }
        });

        const loginRequest = {
            scopes: ["user.read"],
            prompt: 'consent',
        }

        pca.loginPopup(loginRequest)
            .then(response => {
                // do something with the response
                alert(response);
            })
            .catch(error => {
                // handle errors
                 alert(error);
            });*/
    },

    methods: {
        normalizeString(str) {
            return str
                .normalize('NFD') // Decompose combined characters
                .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
                .toLowerCase(); // Convert to lowercase
        },
        getPersonSearchMatchScore(person, normalizedInput) {
            const normalizedDisplayName = this.normalizeString(person.displayName);
            let result = {};
            let score = 0;
            for (const inputPart of normalizedInput.split(" ")) {
                result[inputPart] = [];
                for (const namePart of normalizedDisplayName.split(" ")) {
                    let matchScore = 0;
                    if (inputPart === namePart) {
                        matchScore = 10;
                    }
                    else if (namePart.startsWith(inputPart)) {
                        matchScore = 5;
                    }
                    else if (namePart.includes(inputPart)) {
                        matchScore = 2;
                    }
                    result[inputPart].push(matchScore);
                    score += matchScore;
                }
            }

            // If there are multiple search input parts, they should at least match number of name parts
            const numInputParts = normalizedInput.split(" ").length;
            if (numInputParts > 1) {
                let matchCounter = 0;
                for (let i = 0; i < normalizedDisplayName.split(" ").length; ++i) {
                    let namePartScore = 0;
                    for (const matches of Object.values(result)) {
                        namePartScore += matches[i];
                    }
                    if (namePartScore > 0)
                        matchCounter += 1;
                }
                if (matchCounter < numInputParts)
                    score = 0;
            }
            return score;
        },

        filterPeople(people) {
            const inputStr = this.searchInput.replace(/\s+/g, ' ').trim();
            const normalizedInput = this.normalizeString(inputStr);
            const scoredPeople = [];

            people.forEach(person => {
                const score = this.getPersonSearchMatchScore(person, normalizedInput);
                if (score > 0) {
                    //if (person.id == this.$store.state.me?.id && person.tid == this.$store.state.me?.tid)
                    //    score = 999999999999999;
                    scoredPeople.push({ person, score });
                }
            });

            scoredPeople.sort((a, b) => b.score - a.score);
            return scoredPeople.map(entry => entry.person);
        },
        groupChat() {
            if (chat.isSupported()) {
                const chatPromise = chat.openChat({ user: ["hybrooms.001@hybrooms.com"] });
                chatPromise.
                    then((result) => { console.log("Success"); console.log(result); }).
                    catch((error) => { console.log("Failed"); console.log(error); });
            }
            else { console.log("Chat is not supported"); }
            //app.openLink("https://teams.microsoft.com/l/chat/0/0?users=hybrooms.001@hybrooms.com");
        },
        audioCall() {
            call.startCall({ requestedModalities: ["audio", "data", "videobasedscreensharing"], targets: ["hybrooms.001@hybrooms.com"] });
            //app.openLink("https://teams.microsoft.com/l/call/0/0?users=hybrooms.001@hybrooms.com");
        },
        videoCall() {
            call.startCall({ requestedModalities: ["audio", "data", "videobasedscreensharing", "video"], targets: ["hybrooms.001@hybrooms.com"] });
            // app.openLink("https://teams.microsoft.com/l/call/0/0?users=hybrooms.001@hybrooms.com&withVideo=true");
        },
        fetchEventsWithDayOffset(dayOffset) {
            authentication
                .getAuthToken({
                    resources: this.appIdURI,
                    silent: false,
                })
                .then((token) => {
                    this.isLoadingCalendar = true;
                    axios.get("https://userapi.hybrooms.com/auth/login/msteams", {
                        headers: {
                            "Authorization": token,
                            "TimeZoneName": Intl.DateTimeFormat().resolvedOptions().timeZone,
                            "DayOffset": dayOffset,
                            "ClientType": this.$store.state.clientType,
                            "HybRoomsClient": this.$store.state.hybRoomsClient
                        },
                    }).then(response => {
                        this.setEvents(response.data.events);
                    }).catch(() => {
                        this.$buefy.toast.open({
                            duration: 2500,
                            message: "Error: Could not fetch the calendar events for the requested day",
                            type: "is-danger",
                            pauseOnHover: true
                        });
                    }).finally(() => {
                        this.isLoadingCalendar = false;
                    })
                })
                .catch((message) => {
                    alert(message);
                })
        },

        getEventColorByCategory(categories) {
            const COLORS = ["red", "orange", "yellow", "green", "blue", "purple"];
            const COLORS_DE = ["rote", "orangefarbene", "gelbe", "grüne", "blaue", "lila"];
            let color = "primary";
            if (categories.length > 0) {
                const categoryName = categories[0].split(" ")[0].toLowerCase();
                const ind_en = COLORS.indexOf(categoryName);
                const ind_de = COLORS_DE.indexOf(categoryName);
                const max_ind = Math.max(ind_en, ind_de);
                if (max_ind != -1)
                    color = COLORS[max_ind];
            }
            // if(this.$store.state.darkMode)
            //     color += "-dark";
            return color;
        },

        setEvents(events) {
            let result = {};
            Object.entries(events).forEach(([startDateTime, day_events]) => {
                result = [];
                for (let e of day_events) {
                    result.push({
                        id: crypto.randomUUID(),
                        start: new Date(e.start * 1000),
                        end: new Date(e.end * 1000),
                        salutation: "1",
                        name: e.subject,
                        allDay: e.isAllDay,
                        //type: this.appointmentTypes[raw_event.type] ? this.appointmentTypes[raw_event.type].name : '',
                        data: e,
                        class: this.getEventColorByCategory(e.categories)
                    })
                }
                Vue.set(this.$store.state.myEvents, startDateTime, result);
            });
        },

        resetSubscribedOfficeSpace() {
            this.subscribedOfficeSpace.tid = "";
            this.subscribedOfficeSpace.officeSpaceId = "";
        },

        fetchOfficeSpace() {
            if (this.viewTenantId && this.viewedOfficeSpaceId)
                this.openWebSocket();
        },

        getOfficeSpace() {
            hybRoomsConnector.getOfficeSpace(this.viewTenantId, this.viewedOfficeSpaceId)
                .then(response => {
                    this.allRoomsOfTheOfficeSpace = response.data.rooms;
                    this.subscribeOfficeSpace();
                })
                .catch((e) => {
                    // TODO: This relies on the fact that all office spaces have at least one room which is true since the default rooms
                    if (this.defaultOfficeSpace && this.viewTenantId == this.defaultOfficeSpace.tid && this.viewedOfficeSpaceId == this.defaultOfficeSpace.id) {
                        this.$deleteCookie("defaultOfficeSpace");
                    }
                    const title = this.$t("oops");
                    let errorMessage = e.response.status == 400 ? this.$t("sentences.officeSpaceDoesNotExist") : this.$t("unknownError");
                    errorMessage += " " + this.$t("sentences.clickOkToBeRedirectedToMainPage", { ok: this.$t("interaction.OK") });
                    this.openRedirectToMainPageDialog(title, errorMessage);
                })
                .finally(() => {
                    this.gettingOfficeSpace = false;
                });
        },

        openRedirectToMainPageDialog(title, msg) {
            const props = {
                parent: this,
                component: Confirm,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: false,
                canCancel: false,
                props: {
                    title: title,
                    text: msg,
                    confirmButtonText: this.$t("interaction.OK"),
                    cancelButtonText: "",
                    type: "is-primary",
                },
                events: {
                    'confirm': (confirmed) => {
                        confirmed ? this.$router.push(`/office/${this.viewTenantId}`) : {};
                    }
                }
            };
            this.$buefy.modal.open(props);
        },

        goToOfficeSpace(officeSpace) {
            this.$router.push(`/office/${officeSpace.tid}/${officeSpace.id}`);
        },

        isRoomEmpty(roomId) {
            const roomParticipants = this.$store.state.participants?.[this.viewTenantId]?.[this.viewedOfficeSpaceId]?.[roomId] || [];
            return roomParticipants.length > 0;
        },

        joinRoom(room, unsetDoNotDisturb = false) {
            const payload = {
                "action": !unsetDoNotDisturb ? "joinroom" : "unsetdonotdisturb",
                "room": {
                    "tid": room.tid,
                    "officeSpaceId": room.officeSpaceId,
                    "id": room.id
                },
                "me": this.$store.state.me
            };
            if (this.$store.state.demoMode.active && this.$store.state.demoMode.mockOnlineMeeting.active && payload.room.id != "0000") {
                this.$buefy.modal.open({
                    parent: this,
                    component: OnlineMeetingMockup,
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: false,
                    canCancel: false,
                    props: {
                        room: payload.room
                    }
                })
                return;
            }
            const msg = unsetDoNotDisturb ? this.$t("leavingRoom") : this.$t("joiningRoom");
            this.loadingComponentId = this.$showLoading(msg, this.$store.state.demoMode.active ? "" : this.$t("waitingForMicrosoftServers"), 0.15);
            setTimeout(() => this.$closeLoading(this.loadingComponentId), 10 * 1000);
            this.webSocketConnection.send(JSON.stringify(payload));
        },

        unsetDoNotDisturb(room) {
            this.joinRoom(room, true);
        },

        joinMeeting(link) {
            this.$emit("joinMeeting", link)
        },

        clearWebSocketTimeOut() {
            if (this.webSocketTimeOut) {
                clearTimeout(this.webSocketTimeOut);
                this.webSocketTimeOut = null;
            }
        },

        setWebsocketIdleTimeOut(sendPing) {
            if (this.webSocketTimeOut) {
                this.clearWebSocketTimeOut();
                if (sendPing) {
                    this.webSocketConnection.send(JSON.stringify({ "action": "ping" }));
                }
            }
            if (this.webSocketConnection && this.webSocketConnection.readyState == WebSocket.OPEN) {
                this.webSocketTimeOut = setTimeout(() => this.setWebsocketIdleTimeOut(true), (this.$CONSTANTS.WEBSOCKET_IDLE_CONNECTION_TIMEOUT_MINUTES * 60 - this.$CONSTANTS.WEBSOCKET_REFRESH_BUFFER_SECONDS) * 1000);
            }
        },

        handleWebSocketResponse(event) {
            //this.setWebsocketIdleTimeOut(false);  // Clear websocket idle timeout
            if (event.data.length == 0) {
                console.log("empty payload");
                return;
            }
            const response = JSON.parse(event.data);
            if (response && response.actionResponse == "joinroom") {
                this.$closeLoading(this.loadingComponentId);
                if (response.status == "requestedToJoinRoom") {
                    this.$buefy.toast.open(this.$t("requestedToJoinRoom"));
                    return;
                }
                if (response.status == "notAllowed") {
                    this.$buefy.toast.open({
                        // TODO: Change this error message
                        message: this.$t("sentences.youAreNotAuthorizedToPerformThisAction"),
                        // message: this.$t("notAllowedToJoinRoom"),
                        type: "is-danger"
                    });
                    return;
                }
                if (response.link)
                    this.joinMeeting(response.link);
                else
                    this.$buefy.toast.open("sentences.youAreNotAuthorizedToPerformThisAction");
            }
            else if (response && response.actionResponse == "subscribeofficespace") {
                let participants = {};
                participants[response.officeSpaceId] = {};

                for (const roomId in response.participants) {
                    response.participants[roomId].forEach(participant => {
                        if (!participant.tid) {
                            participant.tid = response.tid;
                        }
                        participant.newlyJoined = false;
                    });
                    participants[response.officeSpaceId][roomId] = response.participants[roomId];
                }

                let costs = {};
                costs[response.officeSpaceId] = {};
                for (const roomId in response.costs.value) {
                    costs[response.officeSpaceId][roomId] = { timestamp: response.costs.timestamp, value: response.costs.value[roomId] };
                }

                if (this.$store.state.demoMode.active && this.$store.state.demoMode.showDummyParticipants.active) {
                    const o = this.$store.state.demoMode.showDummyParticipants.officeSpaces;
                    if (o.length == 0 || o.includes(response.officeSpaceId)) {
                        const values = this.overwriteWithDummyParticipants(participants, costs, response, 0);
                        participants = values.participants;
                        costs = values.costs;
                    }
                }

                Vue.set(this.$store.state.participants, response.tid, participants);
                Vue.set(this.$store.state.costs, response.tid, costs);
                this.gettingParticipants = false;
            }
            else if (response && response.actionResponse == "roomUpdate") {
                const room = response.room;
                let participants = {};
                const currentParticipants = this.$store.state.participants?.[room.tid]?.[room.officeSpaceId]?.[room.id] || [];
                for (const p of currentParticipants)
                    participants[p.tid + p.id] = p;

                let ongoingCostChange = 0;
                for (let p of room.joined) {
                    p["tid"] = p.tid || room.tid;
                    p.newlyJoined = true;
                    if (p.tid + p.id in participants) {
                        if (!participants[p.tid + p.id]["uuid"].includes(p.uuid))
                            participants[p.tid + p.id]["uuid"] = participants[p.tid + p.id]["uuid"].concat(p.uuid);
                        if ("doNotDisturb" in p) {
                            participants[p.tid + p.id]["doNotDisturb"] = undefined;
                        }
                        else if ("doNotDisturb" in participants[p.tid + p.id]) {
                            delete participants[p.tid + p.id]["doNotDisturb"];
                        }
                    }
                    else {
                        participants[p.tid + p.id] = p;
                        ongoingCostChange += 1;
                    }
                }
                if (!(room.tid in this.$store.state.participants))
                    Vue.set(this.$store.state.participants, room.tid, {});
                if (!(room.officeSpaceId in this.$store.state.participants[room.tid]))
                    Vue.set(this.$store.state.participants[room.tid], room.officeSpaceId, {});
                Vue.set(this.$store.state.participants[room.tid][room.officeSpaceId], room.id, Object.values(participants));

                for (let user of room.exited) {
                    const userTenantId = user.tid || room.tid;
                    const idx = this.$store.state.participants[room.tid][room.officeSpaceId][room.id].findIndex(p => p.id == user.id && p.tid == userTenantId);
                    if (idx != -1) {
                        const existing_participant = this.$store.state.participants[room.tid][room.officeSpaceId][room.id][idx];
                        const idx_mid = existing_participant.uuid.indexOf(user.uuid[0]);
                        if (idx_mid != -1) {
                            if (existing_participant.uuid.length == 1) {
                                this.$store.state.participants[room.tid][room.officeSpaceId][room.id].splice(idx, 1);
                                ongoingCostChange -= 1;
                            }
                            else {
                                this.$store.state.participants[room.tid][room.officeSpaceId][room.id][idx].uuid.splice(idx_mid, 1);
                            }
                        }
                    }
                }

                const ts = response.timestamp;
                let current_costs = this.$store.state.costs?.[room.tid]?.[room.officeSpaceId]?.[room.id] || { timestamp: ts, value: { fixed: 0, ongoing: 0 } };
                current_costs.value.fixed += Math.max(0, ts - current_costs.timestamp) * current_costs.value.ongoing;
                current_costs.value.ongoing += ongoingCostChange;
                current_costs.timestamp = ts;

                if (this.$store.state.participants[room.tid][room.officeSpaceId][room.id].length == 0) {
                    // Set last cost
                    if (!(room.tid in this.$store.state.lastCosts))
                        Vue.set(this.$store.state.lastCosts, room.tid, {});
                    if (!(room.officeSpaceId in this.$store.state.lastCosts[room.tid]))
                        Vue.set(this.$store.state.lastCosts[room.tid], room.officeSpaceId, {});
                    Vue.set(this.$store.state.lastCosts[room.tid][room.officeSpaceId], room.id, Math.round(current_costs.value.fixed));
                    current_costs.value.fixed = 0;
                }
                if (!(room.tid in this.$store.state.costs))
                    Vue.set(this.$store.state.costs, room.tid, {});
                if (!(room.officeSpaceId in this.$store.state.costs[room.tid]))
                    Vue.set(this.$store.state.costs[room.tid], room.officeSpaceId, {});
                Vue.set(this.$store.state.costs[room.tid][room.officeSpaceId], room.id, current_costs);
            }
            else if (response && response.actionResponse == "officeSpaceUpdate") {
                for (const room of response.deleted) {
                    this.$store.state.rooms = this.$store.state.rooms.filter(r => r.id != room.id || r.officeSpaceId != room.officeSpaceId || r.tid != room.tid);
                    const currentParticipants = this.$store.state.participants?.[room.tid]?.[room.officeSpaceId]?.[room.id] || [];
                    if (currentParticipants.length > 0)
                        this.$store.state.participants[room.tid][room.officeSpaceId][room.id]
                }
                for (const room of response.created) {
                    this.$store.state.rooms.push(room);
                }
            }
            if (response && response.actionResponse == "doNotDisturb") {
                this.$closeLoading(this.loadingComponentId);
                if (!response.success)
                    this.$buefy.toast.open({
                        // TODO: Change this error message
                        message: response.value ? "Failed to join DoNotDisturb" : "Failed to leave DoNotDisturb",
                        // message: this.$t("notAllowedToJoinRoom"),
                        type: "is-danger"
                    });
            }
        },

        openWebSocket() {
            this.gettingOfficeSpace = true;
            if (this.webSocketConnection && (this.webSocketConnection.readyState == WebSocket.OPEN || this.webSocketConnection.readyState == WebSocket.OPENING)) {
                this.getOfficeSpace();
                return;
            }
            this.webSocketConnection = new WebSocket(webSocketEndpoint);
            this.webSocketConnection.onmessage = this.handleWebSocketResponse;
            this.webSocketConnection.onopen = this.webSocketOnOpen;
            this.webSocketConnection.onclose = this.webSocketOnClose;
            window.addEventListener('beforeunload', this.closeWebSocket);
        },

        webSocketOnOpen() {
            this.retryWebsocketCount = 0;
            this.setWebsocketIdleTimeOut(false);
            this.getOfficeSpace();
        },

        webSocketOnClose() {
            this.clearWebSocketTimeOut();
            this.resetSubscribedOfficeSpace();
            setTimeout(() => this.fetchOfficeSpace(), 500 * this.retryWebsocketCount++);
        },

        closeWebSocket() {
            if (this.webSocketConnection) {
                this.webSocketConnection.onclose = undefined;
                this.webSocketConnection.close();  // Close WebSocket connection
                console.log("Closed web socket");
            } else {
                console.log("Websocket not initialized yet");
            }
        },

        subscribeOfficeSpace() {
            if (this.roomIdToJoin && this.roomIdToJoin != "0000") {
                this.joinRoom({ tid: this.viewTenantId, officeSpaceId: this.viewedOfficeSpaceId, id: this.roomIdToJoin });
                this.roomIdToJoin = undefined;
            }
            if (this.$store.state.viewTenantId == this.subscribedOfficeSpace.tid && this.$store.state.viewedOfficeSpaceId == this.subscribedOfficeSpace.officeSpaceId) {
                return;
            }
            this.gettingParticipants = true;
            const payload = {
                "action": "subscribeofficespace",
                "officeSpace": {
                    "tid": this.$store.state.viewTenantId,
                    "id": this.$store.state.viewedOfficeSpaceId
                },
                "me": this.$store.state.me
            };
            this.webSocketConnection.send(JSON.stringify(payload));
            this.subscribedOfficeSpace = {
                "tid": JSON.parse(JSON.stringify(this.$store.state.viewTenantId)),
                "officeSpaceId": JSON.parse(JSON.stringify(this.$store.state.viewedOfficeSpaceId))
            };
        },

        createDummyPeople(numSubsets, minPeople = 0, maxPeople = 1000, deterministic = false) {
            return createDummyPeople(numSubsets, minPeople, maxPeople, deterministic);
        },

        overwriteWithDummyParticipants(participants, costs, response, currentTime = 0) {
            const participantsConfigAll = {
                "49bC": {
                    "0001": { userGroup: "set1", joiningTimes: { "0": 0, "3": 1, "4": 2, "4.75": 3, "5.25": 5, "6.1": 6, "6.5": 7, "7.3": 9 } },
                    "1Oov": { userGroup: "set3", joiningTimes: { "0": 4 } },
                    // "1Bxd": { userGroup: "empty", joiningTimes: { "0": 0 } },
                    // "1iq6": { userGroup: "empty", joiningTimes: { "0": 0 } },
                    "5555": { userGroup: "set4", joiningTimes: { "0": 0, "25.75": 1, "26.25": 2, "26.75": 3, "27": 4, "27.5": 5 } }
                },
                "14UK": {
                    "0001": { userGroup: "set6", joiningTimes: { "0": 0, "1": 1, "2": 2, "2.5": 3 } },
                    "5555": { userGroup: "set7", joiningTimes: { "0": 0, "15": 1, "16": 2, "16.5": 3 } },
                },
                "dNkB": {
                    "3QqR": { userGroup: "set7", joiningTimes: { "0": 0, "1": 1, "3": 2, "4.5": 3 } },
                    "6IEQ": { userGroup: "set1", joiningTimes: { "0": 0, "3": 1, "4": 2, "4.75": 3, "5.25": 5, "6.1": 6, "6.5": 7, "7.3": 9 } },
                }
            };
            const participantsConfig = participantsConfigAll[this.viewedOfficeSpaceId];
            /*const keys = Object.values(participantsConfig).flatMap(entry => Object.keys(entry));
            const uniqueKeys = [...new Set(keys)];
            const sortedUniqueKeys = uniqueKeys.sort((a, b) => a - b);*/
            const dummyPeople = this.createDummyPeople(this.allRoomsOfTheOfficeSpace.map(r => r.id), 2, 11, participantsConfig);
            for (const r of this.allRoomsOfTheOfficeSpace) {
                if (r.id == "0000")
                    continue
                //const existing = participants[response.officeSpaceId]?.[r.id] || [];
                let numDisplayedDummyParticipants = 555;
                let numNewParticipants = 0;
                let initialParticipants = 0;
                let meetingDuration = 1200;
                let updateCosts = false;
                if (r.id in participantsConfig) {
                    const joiningTimes = participantsConfig[r.id].joiningTimes;
                    initialParticipants = joiningTimes["0"];
                    if (initialParticipants == 0) {
                        meetingDuration = currentTime;
                    }
                    const keys = Object.keys(joiningTimes).map(k => parseFloat(k));
                    const tIdx = keys.sort().indexOf(currentTime);
                    if (tIdx == -1) {
                        let latestKey = 0;
                        for (const k of keys) {
                            if (k < currentTime)
                                latestKey = k;
                            else
                                break;
                        }
                        numDisplayedDummyParticipants = joiningTimes[latestKey];
                    }
                    else if (tIdx > -1) {
                        numNewParticipants = tIdx == 0 ? 0 : joiningTimes[keys[tIdx]] - joiningTimes[keys[tIdx - 1]];
                        if (tIdx < keys.length - 1) {
                            numDisplayedDummyParticipants = joiningTimes[currentTime];
                            const nextTime = keys[tIdx + 1];
                            updateCosts = true;
                            setTimeout(() => this.overwriteWithDummyParticipants(participants, costs, response, nextTime), (nextTime - currentTime) * 1000);
                        }
                    }
                }
                const dp = dummyPeople?.[r.id] || [];
                participants[response.officeSpaceId][r.id] = dp.slice(0, numDisplayedDummyParticipants);
                for (const participantIdx in participants[response.officeSpaceId][r.id]) {
                    if (participantIdx >= participants[response.officeSpaceId][r.id].length - numNewParticipants)
                        participants[response.officeSpaceId][r.id][participantIdx].newlyJoined = true;
                }
                const numParticipants = initialParticipants > 0 ? initialParticipants : participants[response.officeSpaceId][r.id].length;
                if (currentTime == 0 || updateCosts) {
                    costs[response.officeSpaceId][r.id] = { timestamp: new Date().getTime() / 1000 - meetingDuration, value: { fixed: meetingDuration + currentTime * numParticipants, ongoing: numParticipants } }
                }
            }
            return { participants: participants, costs: costs };
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../assets/generated/colors.scss";

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary;
    color: $primary;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary;
    color: $primary;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }

    30% {
        box-shadow: 9984px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px;
    }
}

@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }

    30% {
        box-shadow: 9999px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px;
    }
}

@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }

    30% {
        box-shadow: 10014px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px;
    }
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    z-index: 10;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    z-index: 99;
}
</style>