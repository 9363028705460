<template>
    <div :class="roomBoxClass" v-on:click="enterRoom">
        <div class="columns is-mobile mb-0">
            <div class="column">
                <div class="columns is-mobile">
                    <div class="column is-narrow" v-if="roomData.isPrivate">
                        <b-field>
                            <b-icon icon="lock" size="is-small"></b-icon>
                        </b-field>
                    </div>
                    <div class="column">
                        <h6 class="title is-6 room-box" style="word-wrap: break-word;">{{ roomData.name }}</h6>
                    </div>
                    <div class="column is-narrow"
                        v-if="roomData.doNotDisturb || !roomData.video || !roomData.audio || !roomData.chat">
                        <b-field grouped>
                            <b-field v-if="roomData.doNotDisturb">
                                <b-icon icon="ban" size="is-small"></b-icon>
                            </b-field>
                            <b-field v-if="roomData.doNotDisturb || !roomData.video">
                                <b-icon icon="video-slash" size="is-small"></b-icon>
                            </b-field>
                            <b-field v-if="roomData.doNotDisturb || !roomData.audio">
                                <b-icon icon="microphone-slash" size="is-small"></b-icon>
                            </b-field>
                            <b-field v-if="roomData.doNotDisturb || !roomData.chat">
                                <b-icon icon="comment-slash" size="is-small"></b-icon>
                            </b-field>
                        </b-field>
                    </div>
                </div>
            </div>
            <div class="column is-narrow">
                <b-field v-if="roomData.doNotDisturb">
                    <span v-if="isCurrentRoom" v-on:click="unsetDoNotDisturb" ref="ellipsis"
                        class="icon is-clickable custom-hover-primary">
                        <b-icon pack="fas" icon="sign-out-alt" size="is-medium" />
                    </span>
                </b-field>
                <b-field v-else grouped>
                    <b-field v-if="hasPeople" class="is-flex is-align-items-center">
                        <div class="blinking-green"></div>
                    </b-field>
                    <b-field v-if="displayCosts">
                        <b-tag style="border-radius: 5px;" :type="hasPeople ? 'is-info' : 'is-info is-light'">{{
                            formattedCost }}</b-tag>
                    </b-field>
                    <b-dropdown v-if="people.length == 0 && !isSpecialRoom" aria-role="list"
                        :position="dropdownPosition" v-on:active-change="setDropdownPosition"
                        :max-height="dropdownMaxHeight" @mouseover.native="preventJoin" @mouseleave.native="allowJoin">
                        <template #trigger>
                            <span ref="ellipsis" class="icon is-clickable custom-hover-primary">
                                <b-icon pack="fas" icon="ellipsis-h" />
                            </span>
                        </template>

                        <b-dropdown-item aria-role="listitem" v-on:click="roomSettings">
                            <b-field grouped class="custom-hover-primary">
                                <b-field>
                                    <b-icon pack="fas" icon="cog" size="is-small" />
                                </b-field>
                                <b-field>
                                    {{ $t("roomSettings") }}
                                </b-field>
                            </b-field>
                        </b-dropdown-item>

                        <b-dropdown-item separator />

                        <b-dropdown-item aria-role="listitem" v-on:click="deleteRoom">
                            <b-field grouped class="custom-hover-red">
                                <b-field>
                                    <b-icon pack="fas" icon="trash" size="is-small" />
                                </b-field>
                                <b-field>
                                    {{ $t("deleteRoom") }}
                                </b-field>
                            </b-field>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-field>
            </div>
        </div>
        <b-field ref="personContainer" grouped group-multiline style="height: 56px; width: 100%;">
            <b-field class="stackedPeople" v-for="person in displayedPeople" :key="person.name"
                @mouseenter.native="enteredPersonContainer" @mouseleave.native="allowJoin">
                <person :class="getPersonClass(person)" :user="person" :key="person.tid + person.id"
                    :size="avatarSize" />
            </b-field>
            <b-field v-if="people.length > numMaxAvatars" @mouseenter.native="enteredPersonContainer"
                @mouseleave.native="allowJoin">
                <person :class="stackedPeopleClass" :size="avatarSize"
                    :placeholder="'+' + String(people.length - numMaxAvatars + 1)" src="" :user="{}"
                    :stackedUsers="stackedPeople" />
            </b-field>
        </b-field>
        <div v-if="!roomData.doNotDisturb" :class="footerFlexSettings">
            <img v-if="isInMultiClientOfficeSpace && roomData.client == $CONSTANTS.MS_TEAMS_CLIENT_NAME" class="image"
                style="max-height: 20px;" src="@/assets/microsoft-teams.svg" />
            <div v-if="isInMultiClientOfficeSpace && roomData.client == $CONSTANTS.ZOOM_CLIENT_NAME"
                style="max-width: 48px;">
                <img v-if="isDarkMode" src="@/assets/zoom_logo_white.svg" />
                <img v-else src="@/assets/zoom_logo_blue.svg" />
            </div>
            <copy-icon :tooltip="$t('copyRoomLink')" :successMessage="$t('copiedLink')"
                :failureMessage="$t('failedToCopyTheLink')" :text="roomLink" @mouseover.native="preventJoin"
                @mouseleave.native="allowJoin"></copy-icon>
        </div>
    </div>
</template>

<script>
import {
    hybRoomsConnector
} from '@/lib/connector';

import Person from "@/components/Person.vue";
import Confirm from "@/components/Confirm.vue";
import RoomSettingsModal from '@/components/RoomSettingsModal.vue';
import CopyIcon from "@/components/CopyIcon.vue";

export default {
    components: {
        "person": Person,
        "copy-icon": CopyIcon
    },

    props: {
        roomData: {
            type: Object,
            required: true
        },
        roomIndex: {
            type: Number,
            required: true
        },
        assertAttributeExists: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        isCurrentRoom() {
            return -1 != this.people.findIndex(p => p.id == this.$store.state.me.id && p.tid == this.$store.state.me.tid);
        },

        isSpecialRoom() {
            return this.$CONSTANTS.RESERVED_ROOM_IDS.includes(this.roomData.id);
        },

        roomBoxClass() {
            let cls = "box room-box rounded-borders";
            if (this.isCurrentRoom)
                cls += " current-room";
            else
                cls += " is-clickable";
            return cls;
        },

        numMaxAvatars() {
            if (this.isMounted && this.windowWidth > 0) {
                const bounds = this.$refs.personContainer.$el.getBoundingClientRect();
                // console.log(bounds);
                return Math.floor(bounds.width / (this.avatarSize + 16));
            }
            return 3;
        },

        numDisplayedPeople() {
            return this.people.length > this.numMaxAvatars ? this.numMaxAvatars - 1 : this.people.length;
        },

        people() {
            const people = this.$store.state.participants?.[this.roomData.tid]?.[this.roomData.officeSpaceId]?.[this.roomData.id] || [];
            let uniquePeople = {}
            for (const p of people) {
                uniquePeople[p.tid + p.id] = p;
            }
            uniquePeople = Object.values(uniquePeople);
            if (this.assertAttributeExists)
                return uniquePeople.filter(p => this.assertAttributeExists in p);
            return uniquePeople;
        },

        bounds() {
            if (this.isMounted) {
                return this.$refs.personContainer.$el.getBoundingClientRect();
            }
            return 1;
        },

        hasPeople() {
            return this.people.length > 0;
        },

        costs() {
            return this.$store.state.costs?.[this.roomData.tid]?.[this.roomData.officeSpaceId]?.[this.roomData.id] || {};
        },

        fixedCost() {
            return this.costs?.value?.fixed || 0;
        },

        ongoingCost() {
            return this.costs?.value?.ongoing || 0;
        },

        ongoingCostStartTimestamp() {
            return this.costs?.timestamp || 0;
        },

        liveCostSeconds() {
            return Math.round(this.fixedCost + Math.max(0, this.now - this.ongoingCostStartTimestamp) * this.ongoingCost);
        },

        liveCost() {
            return this.convertSecondsCostsToMoney(this.liveCostSeconds);
        },

        lastCost() {
            const lastCost = this.$store.state.lastCosts?.[this.roomData.tid]?.[this.roomData.officeSpaceId]?.[this.roomData.id] || 0;
            return this.convertSecondsCostsToMoney(lastCost);
        },

        displayCosts() {
            return this.ongoingCost > 0 || this.lastCost > 0;
        },

        formattedCost() {
            const value = this.hasPeople ? this.liveCost : this.lastCost;
            return new Intl.NumberFormat(this.$i18n.locale, {
                style: 'currency',
                currency: this.$store.state.CURRENCY,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);
        },

        displayedPeople() {
            return this.people.slice(0, this.numDisplayedPeople);
        },

        stackedPeople() {
            return this.people.slice(this.numDisplayedPeople);
        },

        stackedPeopleClass() {
            if (this.animateStackedPeople)
                return "zoom-on-join";
            return "";
        },

        roomLink() {
            if (this.$store.state.hybRoomsClient == this.$CONSTANTS.MS_TEAMS_CLIENT_NAME)
                return `https://teams.microsoft.com/l/entity/${this.$CONSTANTS.CLIENT_IDS.msteams[process.env.NODE_ENV]}/init?context=%7B%22subEntityId%22%3A%22${this.roomData.tid}.${this.roomData.officeSpaceId}.${this.roomData.id}%22%7D`;
            return this.$getInitUrl(this.roomData.tid, this.roomData.officeSpaceId, this.roomData.id);
        },

        footerFlexSettings() {
            const horizontal_alignment = this.isInMultiClientOfficeSpace ? "space-between" : "right";
            return `is-flex is-align-items-center is-justify-content-${horizontal_alignment}`;
        },

        avatarSize() {
            return this.$CONSTANTS.AVATAR_SIZE_PX;
        },

        isInMultiClientOfficeSpace() {
            const client = this.$store.state.officeSpaces.filter(o => o.id == this.$store.state.viewedOfficeSpaceId && o.tid == this.$store.state.viewTenantId)[0].client;
            return client == this.$CONSTANTS.MULTI_CLIENT_NAME || client == this.$CONSTANTS.PUBLIC_CLIENT_NAME;
        },

        isDarkMode() {
            return this.$store.state.darkMode;
        },

        windowWidth() {
            return this.$store.state.windowWidth;
        }
    },

    data() {
        return {
            now: this.setNow(),
            costUpdateDelay: 0,
            animateStackedPeople: false,
            copiedLink: false,
            dropdownMaxHeight: 200,
            dropdownPosition: "is-bottom-right",
            preventMeetingLinkOpen: false,
            isMounted: false    // Trick to wait for refs to be available https://stackoverflow.com/questions/42615445/vuejs-2-0-emit-event-from-grand-child-to-his-grand-parent-component
        }
    },

    mounted() {
        this.setNow();
        this.updateNow();
        this.isMounted = true;
    },

    methods: {
        preventJoin() {
            this.preventMeetingLinkOpen = true;
        },

        allowJoin() {
            this.preventMeetingLinkOpen = false;
        },

        enteredPersonContainer() {
            if (Object.keys(this.people).length === 0)
                return;
            this.preventJoin();
        },

        getPersonClass(person) {
            let cls = "stackedPerson";
            if (person.newlyJoined)
                cls += " zoom-on-join";
            return cls
        },

        convertSecondsCostsToMoney(secondsCost) {
            const cost = this.$store.state.COST_PER_HOUR * secondsCost / 3600;
            return Math.round(cost * 100) / 100;
        },

        enterRoom() {
            if (!this.preventMeetingLinkOpen && !this.isCurrentRoom) {
                this.$emit("joinRoom", this.roomData);
            }
        },

        unsetDoNotDisturb() {
            if (this.isCurrentRoom)
                this.$emit("unsetDoNotDisturb", this.roomData);
        },

        setNow() {
            this.now = new Date().getTime() / 1000 - this.costUpdateDelay;
        },

        updateNow() {
            const interval = this.$store.state.demoMode.costUpdateIntervalOverride.active ? this.$store.state.demoMode.costUpdateIntervalOverride.value : this.$CONSTANTS.COST_UPDATE_INTERVAL_MS;
            setTimeout(() => {
                this.setNow();
                this.updateNow()
            }, interval)
        },

        roomSettings() {
            this.$buefy.modal.open({
                parent: this,
                component: RoomSettingsModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: false,
                props: {
                    roomData: JSON.parse(JSON.stringify(this.roomData)),
                    roomIndex: this.roomIndex
                }
            })
        },

        deleteRoom() {
            this.$buefy.modal.open({
                parent: this,
                component: Confirm,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: false,
                props: {
                    text: this.$t("sentences.areYouSureToDeleteTheRoom", { roomName: this.roomData.name }),
                    confirmButtonText: this.$t("interaction.delete"),
                    title: this.$t("deletingX", { x: this.roomData.name }),
                    type: "is-danger"
                },
                events: {
                    'confirm': (confirmed) => {
                        if (!confirmed)
                            return;
                        const loadingComponentId = this.$showLoading(this.$t("deletingX", { x: this.roomData.name }));
                        setTimeout(() => this.$closeLoading(loadingComponentId), 10 * 1000);
                        hybRoomsConnector.deleteRoom(this.roomData)
                            .then(response => {
                                let deleted = false;
                                if (response.status < 300) {
                                    deleted = true;
                                    const idx = this.$store.state.rooms.findIndex((r) => r.id == this.roomData.id && r.officeSpaceId == this.roomData.officeSpaceId && r.tid == this.roomData.tid);
                                    if (idx >= 0) {
                                        this.$store.state.rooms.splice(idx, 1);
                                        deleted = true;
                                    }
                                }
                                this.notifyDelete(deleted);
                            })
                            .finally(() => this.$closeLoading(loadingComponentId));
                    }
                }
            })
        },

        notifyDelete(deleted) {
            this.$buefy.toast.open({
                duration: 2500,
                message: deleted ? this.$t("deletedRoom") : this.$t("failedToDeleteRoom"),
                type: deleted ? "is-dark" : "is-danger",
                pauseOnHover: true
            });
        },

        setDropdownPosition() {
            const bounds = this.$refs.ellipsis.getBoundingClientRect();
            const horizontal = window.innerWidth - bounds.right < 350 ? "left" : "right";
            const vertical = window.innerHeight - bounds.bottom < this.dropdownMaxHeight + 5 ? "top" : "bottom";
            this.dropdownPosition = `is-${vertical}-${horizontal}`;
        }
    },
    watch: {
        stackedPeople(newValue, oldValue) {
            if (newValue.length == 0)
                return;
            if (newValue.length > oldValue.length)
                this.animateStackedPeople = true;
            else {
                const lastPerson = newValue.slice(-1)[0];
                const isLastPersonNew = !oldValue.map(p => p.tid + p.id).includes(lastPerson.tid + lastPerson.id);
                this.animateStackedPeople = isLastPersonNew;
            }
            if (this.animateStackedPeople)
                setTimeout(() => this.animateStackedPeople = false, 750);
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../assets/generated/colors.scss";

.stackedPeople {
    .stackedPerson:not(:last-child) {
        margin-right: .5em
    }

    .stackedPerson {
        margin-bottom: .5em
    }
}

@keyframes blink-green {

    from,
    to {
        opacity: 0;
        background-color: green;
    }

    50% {
        opacity: 1;
    }
}

.blinking-green {
    animation: 1s blink-green infinite;
    width: 7px;
    height: 7px;
    border-radius: 100%;
}

@keyframes zoom-animation {

    from,
    to {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.25, 1.25);
    }
}

.zoom-on-join {
    animation: 0.75s zoom-animation ease-out;
}

/*.field.is-grouped.is-grouped-multiline {
    row-gap: 0.75rem;
    padding-bottom: 0.75rem;
}*/

.current-room {
    border-style: solid;
    border-color: $grey;
}
</style>