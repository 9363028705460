<template>
    <div class="is-flex is-justify-content-center">
        <b-button v-on:click="clicked" type="is-primary" icon-left="users" :style="style"
            :size="isMobile ? '' : 'is-medium'" rounded outlined>{{
                $t("quickCall")
            }}</b-button>
    </div>
</template>

<script>
export default {
    computed: {
        style() {
            return "position:fixed; bottom: 3.75rem; z-index: 30;";
        },
        isMobile() {
            return this.$store.state.windowWidth < this.$store.state.BREAKPOINTS.tablet;
        }
    },
    methods: {
        clicked() {
            this.$emit("createAdHocMeeting");
        }
    }
}
</script>